import React, { FC } from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { Box } from "@material-ui/core"
import "./layout.scss"

interface Props {
  pageCanonical?: string
  pageTitle?: string
  pageDescription?: string
}

const websiteTitle = "Global Custodian Solutions"
const websiteCanonical = "https://www.globalcustodiansolutions.com/"
const websiteDescription = "Global Custodian Solutions website"

const Layout: FC<Props> = ({
  children,
  pageCanonical,
  pageTitle,
  pageDescription,
}) => {
  return (
    <>
      <GatsbySeo
        title={websiteTitle}
        description={websiteDescription}
        canonical={pageCanonical ?? websiteCanonical}
        openGraph={{
          url: pageCanonical ?? websiteCanonical,
          title: pageTitle ?? websiteTitle,
          description: pageDescription ?? websiteDescription,
          images: [
            {
              url: "https://www.globalcustodiansolutions.com/logo.png",
              width: 800,
              height: 600,
              alt: "globalcustodiansolutions",
            },
            {
              url: "https://www.globalcustodiansolutions.com/logo.png",
              width: 900,
              height: 800,
              alt: "globalcustodiansolutions",
            },
            { url: "https://www.globalcustodiansolutions.com/logo.png" },
          ],
          site_name: "Global Custodian Solutions",
        }}
        language="en"
      />
      <Box display="flex" flexDirection="column" className={"achernar-layout"}>
        {children}
      </Box>
    </>
  )
}

export default Layout
